* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}

@font-face {
  font-family: 'Typo Hoop Demo';
  src: url(./fonts/RPFonts/Typo_Hoop_Bold_Demo.otf) format('opentype');
}

@font-face {
  font-family: 'Typo Hoop Hairline Demo';
  src: url(./fonts/RPFonts/Typo_Hoop_Hairline_Demo.otf) format('opentype');
}

@font-face {
  font-family: 'Typo Hoop Light Demo';
  src: url(./fonts/RPFonts/Typo_Hoop_Light_Demo.otf) format('opentype');
}

@font-face {
  font-family: 'Typo Hoop Regular Demo';
  src: url(./fonts/RPFonts/Typo_Hoop_Regular_Demo.otf) format('opentype');
}

@font-face {
  font-family: 'TTHoves Black';
  src: url(./fonts/RPFonts/TTHoves-Black.ttf) format('truetype');
}

@font-face {
  font-family: 'TTHoves Bold';
  src: url(./fonts/RPFonts/TTHoves-Black.ttf) format('truetype');
}

@font-face {
  font-family: 'TTHoves Thin';
  src: url(./fonts/RPFonts/TTHoves-Thin.ttf) format('truetype');
}

@font-face {
  font-family: 'TTHoves-Medium';
  src: url(./fonts/RPFonts/TTHoves-Medium.ttf) format('truetype');
}

@font-face {
  font-family: 'TTHoves-Italic';
  src: url(./fonts/RPFonts/TTHoves-Italic.ttf) format('truetype');
}

@font-face {
  font-family: 'Gotham Black';
  src: url(./fonts/Gotham_font/Gotham-Black.otf) format('truetype');
}

@font-face {
  font-family: 'Gotham-BlackItalic';
  src: url(./fonts/Gotham_font/Gotham-BlackItalic.otf) format('truetype');
}

@font-face {
  font-family: 'Gotham-Bold';
  src: url(./fonts/Gotham_font/Gotham-Bold.otf) format('truetype');
}

@font-face {
  font-family: 'Gotham-BoldItalic';
  src: url(./fonts/Gotham_font/Gotham-BoldItalic.otf) format('truetype');
}

@font-face {
  font-family: 'Gotham-Book';
  src: url(./fonts/Gotham_font/Gotham-Book.otf) format('truetype');
}

@font-face {
  font-family: 'Fnord-Italic.otf';
  src: url(./fonts/Fnord-Italic.otf) format('truetype');
}

@font-face {
  font-family: 'Fnord';
  src: url(./fonts/Fnord.otf) format('truetype');
}

@font-face {
  font-family: 'Larome';
  src: url(./fonts/Larome.otf) format('truetype');
}

@font-face {
  font-family: 'HelveticaNowText-Regular';
  src: url(./fonts/HelveticaNowText/HelveticaNowText-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'HelveticaNowText-Medium';
  src: url(./fonts/HelveticaNowText/HelveticaNowText-Medium.ttf) format('truetype');
}

@font-face {
  font-family: 'HelveticaNowText-Thin';
  src: url(./fonts/HelveticaNowText/HelveticaNowText-Thin.ttf) format('truetype');
}

/* ========== simply-cricket-font ========== */
@font-face {
  font-family: 'Simply Cricket Book';
  src: url('./fonts/Simply_Cricket/SimplyCricket-BoldItalic.woff2') format('woff2'),
    url('./fonts/Simply_Cricket/SimplyCricket-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Simply Cricket Book';
  src: url('./fonts/Simply_Cricket/SimplyCricket-Bold.woff2') format('woff2'),
    url('./fonts/Simply_Cricket/SimplyCricket-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Simply Cricket';
  src: url('./fonts/Simply_Cricket/SimplyCricket-MediumItalic.woff2') format('woff2'),
    url('./fonts/Simply_Cricket/SimplyCricket-MediumItalic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Simply Cricket Book';
  src: url('./fonts/Simply_Cricket/SimplyCricket-Book.woff2') format('woff2'),
    url('./fonts/Simply_Cricket/SimplyCricket-Book.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Simply Cricket';
  src: url('./fonts/Simply_Cricket/SimplyCricket-DemiboldItalic.woff2') format('woff2'),
    url('./fonts/Simply_Cricket/SimplyCricket-DemiboldItalic.woff') format('woff');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Simply Cricket Book';
  src: url('./fonts/Simply_Cricket/SimplyCricket-BookItalic.woff2') format('woff2'),
    url('./fonts/Simply_Cricket/SimplyCricket-BookItalic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Simply Cricket';
  src: url('./fonts/Simply_Cricket/SimplyCricket-BlackItalic.woff2') format('woff2'),
    url('./fonts/Simply_Cricket/SimplyCricket-BlackItalic.woff') format('woff');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Simply Cricket';
  src: url('./fonts/Simply_Cricket/SimplyCricket-Demibold.woff2') format('woff2'),
    url('./fonts/Simply_Cricket/SimplyCricket-Demibold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Simply Cricket';
  src: url('./fonts/Simply_Cricket/SimplyCricket-Light.woff2') format('woff2'),
    url('./fonts/Simply_Cricket/SimplyCricket-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Simply Cricket';
  src: url('./fonts/Simply_Cricket/SimplyCricket-LightItalic.woff2') format('woff2'),
    url('./fonts/Simply_Cricket/SimplyCricket-LightItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Simply Cricket';
  src: url('./fonts/Simply_Cricket/SimplyCricket-Black.woff2') format('woff2'),
    url('./fonts/Simply_Cricket/SimplyCricket-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Simply Cricket Cond Book';
  src: url('./fonts/Simply_Cricket/SimplyCricketCond-Bold.woff2') format('woff2'),
    url('./fonts/Simply_Cricket/SimplyCricketCond-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Simply Cricket Cond Book';
  src: url('./fonts/Simply_Cricket/SimplyCricketCond-Book.woff2') format('woff2'),
    url('./fonts/Simply_Cricket/SimplyCricketCond-Book.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Simply Cricket';
  src: url('./fonts/Simply_Cricket/SimplyCricket-Medium.woff2') format('woff2'),
    url('./fonts/Simply_Cricket/SimplyCricket-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Simply Cricket Cond';
  src: url('./fonts/Simply_Cricket/SimplyCricketCond-Medium.woff2') format('woff2'),
    url('./fonts/Simply_Cricket/SimplyCricketCond-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Simply Cricket Cond';
  src: url('./fonts/Simply_Cricket/SimplyCricketCond-Demibold.woff2') format('woff2'),
    url('./fonts/Simply_Cricket/SimplyCricketCond-Demibold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

/* ========== simply-cricket-font ========== */

.capitalize-first-letter::first-letter {
  text-transform: uppercase;
}

.swal2-container {
  z-index: 999999999;
}

/*================ global-css-for-dashboard-tables ================*/
.search-box {
  form {
    border-radius: 100px;
    background: #fff;
    box-shadow: 0px 0px 30px 0px #ebebeb;
    padding: 3px 5px 3px 15px;
  }
}

.table-bordered {
  background: #fff;
  border-radius: 30px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  -ms-border-radius: 30px;
  -o-border-radius: 30px;
  overflow: hidden;
  box-shadow: 0 0 30px 0 #ebebeb;
  font-family: 'TTHoves-Medium';

  thead {
    tr {
      th {
        background: #004ccf;
        color: #fff;
        border: none;
        padding: 18px 0px 18px 20px;
        font-weight: 500;

        &:last-child {
          padding-right: 20px;
        }
      }
    }
  }

  tbody {
    tr {
      border-color: #dadada;

      td {
        border: none;
        padding: 18px 0px 18px 20px;
        font-size: 15px;
        font-family: 'TTHoves-Medium';

        &:nth-last-child(2) {
          button {
            white-space: nowrap;
          }
        }

        &:last-child {
          white-space: nowrap;
          display: flex;
          gap: 6px;
          padding-right: 13px;

          button:nth-child(1) {
            background: #f15f3d;
            border-radius: 6px;
            -webkit-border-radius: 6px;
            -moz-border-radius: 6px;
            -ms-border-radius: 6px;
            -o-border-radius: 6px;
            color: #fff;
            padding: 5px;

            svg {
              width: 0.8em;
              height: 0.8em;

              path {
                fill: #fff;
              }
            }
          }

          button:nth-child(2) {
            background: #f3c42b;
            border-radius: 6px;
            -webkit-border-radius: 6px;
            -moz-border-radius: 6px;
            -ms-border-radius: 6px;
            -o-border-radius: 6px;
            color: #fff;
            padding: 5px;

            svg {
              width: 0.8em;
              height: 0.8em;
            }
          }

          button:nth-child(3) {
            background: #0c4ccf;
            border-radius: 6px;
            -webkit-border-radius: 6px;
            -moz-border-radius: 6px;
            -ms-border-radius: 6px;
            -o-border-radius: 6px;
            color: #fff;
            padding: 5px;

            svg {
              width: 0.8em;
              height: 0.8em;
            }
          }
        }
      }

      &:last-child {
        border: none;
      }
    }
  }
}

.MuiButton-outlinedPrimary {
  border-radius: 100px;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  -ms-border-radius: 100px;
  -o-border-radius: 100px;
}

.MuiPagination-ul .Mui-selected {
  background-color: #f7acac !important;
  color: #000;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  border-radius: 10px !important;
  -webkit-border-radius: 10px !important;
  -moz-border-radius: 10px !important;
  -ms-border-radius: 10px !important;
  -o-border-radius: 10px !important;
}

.MuiPaginationItem-page {
  font-family: 'TTHoves-Medium' !important;
}

/*=============== /global-css-for-dashboard-tables ================*/

/*================== cricket-global-css =================*/
.cricketmainmodule {
  background: url(../public/assets/tlc/cricketimages/greenbg.png);
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
  font-family: 'Simply Cricket';

  h1,
  h2 {
    font-weight: bold;
  }
}

/*================= /cricket-global-css =================*/

.tag-wrapper {
  background: #fff;
  color: #000;
  padding: 1px 9px;
  border-radius: 6px;
  margin-right: 2px;
  display: inline-block;
  margin-bottom: 5px;
}

.tag-wrapper button {
  background: transparent;
  border: 0;
  margin-left: 3px;

  &:focus {
    border: 0;
    outline: 0;
  }
}