@import '../../../styles/variables';
@import '../../../styles/mixins';
.modalLogin .MuiPaper-root {
  border-radius: 30px;
  overflow-y: auto !important;
  @include media-breakpoint-down(md) {
    overflow: scroll !important;
    text-align: center;
  }
}

.login-wrapper-modal {
  display: flex;
  background-color: #fff;
  .signup-box {
    padding: 61px 51px;
    font-family: $fontDescription;
    color: $textPrimary;
    max-width: 565px;
    .title {
      color: $primary_color;
      text-transform: uppercase;
      font-family: $fontTitleItalic;
      font-size: 34px;
      font-style: italic;
      font-weight: 500;
      line-height: normal;
      @include media-breakpoint-down(md) {
        font-size: 28px !important;
      }
    }
    .optin-toggle {
      font-size: 0.8rem;
      text-align: left;
    }
    .description {
      color: $textPrimary;
      /* body-text */
      font-family: $fontDescription;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 22px;
      /* 137.5% */
      margin-bottom: 30px;
    }
    .MuiFormControl-root {
      margin-top: 0;
    }
    .MuiInputBase-root {
      border-radius: 100px;
      border-color: #e3e1dd;
    }
    ::placeholder {
      color: #999;
      font-family: $fontDescription;
      font-size: 13px;
      font-style: normal;
      font-weight: 500;
      line-height: 22px;
      /* 169.231% */
    }
    .primaryButton {
      @include primaryButton;
      background-color: $LoginActionColor;
      color: #fff;
      margin-top: 30px;
      padding-left: 46px;
      padding-right: 46px;
    }
  }
  .img {
  }
}
