@import 'node_modules/bootstrap/scss/functions';
@import 'node_modules/bootstrap/scss/variables';
@import 'node_modules/bootstrap/scss/mixins';

@import './variables';

@mixin ButtonOrange {
  .actionButton {
    border-radius: 100px;
    background: $primary_orange;
    box-shadow: $boxShadow;
    color: #fff;
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    height: 50px;
    width: 192.48px;
    outline: 0;
    border: 0;
    transition: all 0.3s;

    &:hover {
      background: $primary_color_Blue;
    }
  }
}

@mixin primaryButton {
  border-radius: 100px;
  font-family: $fontDescription;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  transition: all 0.3s;
  text-transform: capitalize;
  padding: 16px 34px;
  color: $textPrimary;

  &:hover {
    background: $primary_color !important;
    color: #fff;
  }
}

@mixin pinkButton {
  border-radius: 30px !important;
  font-family: $fontDescription;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  transition: all 0.3s;
  text-transform: capitalize;
  padding: 14px 34px;
  color: #fff;
  background-color: $LoginActionColor !important;
  -webkit-border-radius: 30px !important;
  -moz-border-radius: 30px !important;
  -ms-border-radius: 30px !important;
  -o-border-radius: 30px !important;
}


@mixin cricketPrimryButton {
  width: fit-content;
  padding: 13px 40px 13px 40px;
  border-radius: 8px;
  background: #3170B7;
  font-family: 'Simply Cricket';
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 1px;
  text-align: center;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
  box-shadow: none;
  text-transform: none;
  transition: all ease-in-out 0.4s;
  -webkit-transition: all ease-in-out 0.4s;
  -moz-transition: all ease-in-out 0.4s;
  -ms-transition: all ease-in-out 0.4s;
  -o-transition: all ease-in-out 0.4s;
  margin-top: 30px;
  color: #fff;

  &:hover {
    background: #005603;
  }

  @include media-breakpoint-down(sm) {
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    width: 100%;

    span {
      display: none;
    }
  }
}