@import '../../styles/variables';

.dashboard {
  background-color: $light_graybackground;
  min-height: 100vh;
  font-family: $fontDescription;

  .appBar {
    background-color: $white;
    color: #000;
    box-shadow: none;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: $fontTitle;
  }

  .no-decoration {
    text-decoration: none;
  }

  p {
    font-family: $fontDescription;
  }

  ul,
  li,
  ol,
  li,
  a {
    font-family: $fontDescription;
  }

  main {
    padding: 36px;
  }

  .sidebar-nav {
    ul {
      height: 100%;
      display: flex;
      flex-direction: column;
      padding-bottom: 0px;
      min-height: 770px;

      li {
        border-bottom: 1px solid #496ebb;
        font-family: $fontDescription;

        a {
          font-family: $fontDescription;
          transition: 0.3s;
          -webkit-transition: 0.3s;
          -moz-transition: 0.3s;
          -ms-transition: 0.3s;
          -o-transition: 0.3s;
        }

        .active {
          background: rgba(0, 0, 0, 0.21);
        }

        .MuiListItemIcon-root {
          margin-right: 10px;
        }

        &:last-child {
          // position: absolute;
          // bottom: 0;
          margin-top: 100px;
          border-top: 1px solid #496ebb;
          border-bottom: 0px;

          .MuiButtonBase-root {
            padding: 16px 20px;
            min-height: auto;
          }
        }
      }
    }

    .sidenavsubMenu {
      border-bottom: 1px solid #375aa1;
      font-family: 'TTHoves-Medium';
      background: rgba(0, 0, 0, 0.11);

      .MuiListItemIcon-root {
        min-width: auto;
        margin-right: 10px;
      }
    }
  }
}