@import '../../../styles/variables';
@import '../../../styles/mixins';
.modalLogin .MuiPaper-root {
  border-radius: 30px;
  overflow: hidden;
}

.login-wrapper-modal {
  display: flex;
  background-color: #fff;
  @include media-breakpoint-down(md) {
    flex-wrap: wrap;
  }
  .close-icon {
    position: absolute;
    top: 16px;
    right: 16px;
    background: $LoginActionColor;
    padding: 3px;
    // svg {
    //     width: 70%;
    // }
    &:hover {
      background: $LoginActionColor;
      opacity: 0.9;
    }
  }
  .signup-box {
    padding: 61px 51px;
    display: flex;
    align-items: self-start;
    flex-direction: column;
    justify-content: center;
    font-family: $fontDescription;
    color: $textPrimary;
    @include media-breakpoint-up(md) {
      flex-basis: 55%;
    }
    @include media-breakpoint-down(md) {
      padding: 30px;
    }
    .form-tag {
      width: 100%;
    }
    .title {
      color: $primary_color;
      text-transform: uppercase;
      font-family: $fontTitleItalic;
      font-size: 34px;
      font-style: italic;
      font-weight: 500;
      line-height: normal;
    }
    .description {
      color: $textPrimary;
      /* body-text */
      font-family: $fontDescription;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 22px;
      /* 137.5% */
      margin-bottom: 30px;
    }
    .MuiGrid-item {
      @include media-breakpoint-down(md) {
        padding-top: 6px;
      }
    }
    .MuiFormControl-root {
      margin-top: 0;
    }
    .MuiInputBase-root {
      border-radius: 100px;
      border-color: #e3e1dd;
      input {
        padding: 14px 20px;
      }
    }
    ::placeholder {
      color: #999;
      font-family: $fontDescription;
      font-size: 13px;
      font-style: normal;
      font-weight: 500;
      line-height: 22px;
      /* 169.231% */
    }
    .primaryButton {
      @include primaryButton;
      background-color: $LoginActionColor;
      color: #fff;
      margin-top: 30px;
      padding-left: 70px;
      padding-right: 70px;
      box-shadow: none;
      // width: max-content;
      @include media-breakpoint-down(md) {
        margin-top: 15px;
      }
    }
  }
  .img {
    @include media-breakpoint-down(lg) {
      flex-basis: 45%;
    }
    @include media-breakpoint-down(md) {
      display: none;
    }
    img {
      @include media-breakpoint-down(lg) {
        width: 100%;
        object-fit: cover;
      }
    }
  }
  .reset-password {
    display: flex;
    padding: 5px;
    margin-top: 10px;
    p {
      color: $textPrimary;
      margin-bottom: 0;
      span {
        cursor: pointer;
        color: $LoginActionColor;
      }
    }
  }
}
