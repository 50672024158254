// Colors
$primary_color: #004ccf;
$secondary_color: #f3c42b;
$white: #fff;
$textPrimary: #313738;
$LoginActionColor: #e81961;

// font family

$fontTitle: 'Gotham Black';
$fontTitleItalic: 'Gotham-BlackItalic';
$fontDescription: 'TTHoves-Medium';
$TTHovesThin: 'TTHoves Thin';

// Dashboard color codes
$primary_color_Blue: #004ccf;
$primary_orange: #f15f3d;
$light_graybackground: #f8f8f8;

// Box Shadow

$boxShadow: 0px 0px 30px 0px #ebebeb;
