@import '../../styles/variables';
@import '../../styles/mixins';
.brands {
  padding: 27px 0;
  background-color: #f5f5f5;
  width: 100%;
  // overflow-x: scroll;
  @include media-breakpoint-down(md) {
    padding: 15px 0;
  }
  ul {
    align-items: center;
  }
  li {
    svg {
      width: 100%;
    }
  }
}

.scroll-container {
  display: flex;
  overflow-x: auto;
  white-space: nowrap;
  scroll-behavior: smooth;
}

.scroll-item {
  flex: 0 0 auto;
  margin-right: 10px; /* Adjust the spacing between items */
  width: 100px; /* Set the width of each item */
  height: 50px; /* Set the height of each item */
  background-color: lightblue;
}
